import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";

  function WordListDisplay ({word, translation, traudio}){
      const start = () => {
        const audio = new Audio(`https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/dictionaries/raw/${traudio}`);
        audio.play();
      };
    return (
        <>
            <div className="py-2 px-4">
                <li>
                    <details>
                        <summary>
                        {word}
                        </summary>
                        <div className="px-1 py-2 mx-auto max-w-lg">
                            <div className="card shadow w-full">
                                <div className="card-body">
                                  <button className=" btn text-base">
                                    <div className="px-1 font-bold">
                                    {translation}
                                    </div>
                                    <div className="px-1">
                                    <a className="btn btn-circle btn-secondary" onClick={start}><ion-icon name="play-circle-outline"></ion-icon></a>
                                    </div>
                                  </button>
                                </div>
                            </div>
                        </div>
                    </details>
                </li>
                
            </div>
            <hr></hr>
        </>
    )
}


  function EnFr(){
      const [searchItem, setSearchItem] = useState('')
      const [error, setError] = useState(null);
      const [isLoaded, setIsLoaded] = useState(false);
      const [items, setItems] = useState([]);
    
      useEffect(() => {
        fetch("https://goldfish-app-asx3v.ondigitalocean.app/tools/enfr")
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItems(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
    
      }, [])
    
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div><button className="btn btn-ghost btn-loading"></button></div>;
      } else {



    
      return (
        <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="English to French common expressions dictionary"  />
        </Helmet>
          <div className="px-2 py-4  flex justify-center items-center">

            <input  type="text" placeholder="Search here" className="input input-primary w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
          </div>
      

          <div className="py-6 flex justify-center items-center">
            <ul className="menu menu-vertical px-1 w-full  max-w-lg">
              {items.filter((wrd) => //items here
              {
                if(searchItem === "") {
                  return wrd;
                }
                else if 
                (wrd.Word.toLowerCase().includes(searchItem.toLowerCase())) {
                  return wrd;
                }
                else return null
                
              }).map(wrd => 
              <WordListDisplay 
              key={wrd.Id} 
              word ={wrd.Word} 
              translation={wrd.Translation} 
              traudio={""+wrd.TranslationAudio+".mp3"} >

            </WordListDisplay>)}
            </ul>

          </div>


        </div>
      );}
}

export  {WordListDisplay,EnFr}