import {React, useState, useEffect} from "react";




function  Features(){
    return (

        <section className="relative">
        <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="border-t py-12 [border-image:linear-gradient(to_right,transparent,theme(colors.slate.400/.25),transparent)1] md:py-20">
            {/* Section header */}
            <div className="mx-auto max-w-3xl pb-4 text-center md:pb-12">
              <div className="inline-flex items-center gap-3 pb-3 before:h-px before:w-8 before:bg-gradient-to-r before:from-transparent before:to-indigo-200/50 after:h-px after:w-8 after:bg-gradient-to-l after:from-transparent after:to-indigo-200/50">
                <span className="inline-flex text-indigo-500">
                  Various topics
                </span>
              </div>
              <h2 className="animate-[gradient_6s_linear_infinite] text-indigo-500 pb-4 font-nacelle text-xl font-semibold md:text-2xl">
                All areas of science and beyond
              </h2>
              <p className="text-lg text-gray-700 dark:text-gray-400">
                We cover various topics such as science, literature, arts, religion, self-development and professions.
              </p>
            </div>
            {/* Items */}
            <div className="mx-auto grid max-w-sm gap-12 sm:max-w-none sm:grid-cols-2 md:gap-x-14 md:gap-y-16 lg:grid-cols-3">
              <article>

                    <svg className="fill-indigo-500" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><g><rect fill="none" height="24" width="24"/></g><g><path d="M13,11.33L18,18H6l5-6.67V6h2 M15.96,4H8.04C7.62,4,7.39,4.48,7.65,4.81L9,6.5v4.17L3.2,18.4C2.71,19.06,3.18,20,4,20h16 c0.82,0,1.29-0.94,0.8-1.6L15,10.67V6.5l1.35-1.69C16.61,4.48,16.38,4,15.96,4L15.96,4z"/></g></svg>

                <h3 className="mb-1 font-nacelle text-[1rem] font-semibold text-gray-700 dark:text-gray-200">
                  Science
                </h3>
                <p className="text-gray-700 dark:text-gray-400">
                Science generates solutions for everyday life and helps us answer the universe's great mysteries. 
                There is no doubt that science allows us to develop new technologies that solve some practical problems we face daily. The majority of our books and tutorials deal with scientific topics.
                </p>
              </article>
              <article>
              <svg className="fill-indigo-500" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><g><rect fill="none" height="24" width="24" x="0"/></g><g><path d="M22.47,5.2C22,4.96,21.51,4.76,21,4.59v12.03C19.86,16.21,18.69,16,17.5,16c-1.9,0-3.78,0.54-5.5,1.58V5.48 C10.38,4.55,8.51,4,6.5,4C4.71,4,3.02,4.44,1.53,5.2C1.2,5.36,1,5.71,1,6.08v12.08c0,0.58,0.47,0.99,1,0.99 c0.16,0,0.32-0.04,0.48-0.12C3.69,18.4,5.05,18,6.5,18c2.07,0,3.98,0.82,5.5,2c1.52-1.18,3.43-2,5.5-2c1.45,0,2.81,0.4,4.02,1.04 c0.16,0.08,0.32,0.12,0.48,0.12c0.52,0,1-0.41,1-0.99V6.08C23,5.71,22.8,5.36,22.47,5.2z M10,16.62C8.86,16.21,7.69,16,6.5,16 c-1.19,0-2.36,0.21-3.5,0.62V6.71C4.11,6.24,5.28,6,6.5,6C7.7,6,8.89,6.25,10,6.72V16.62z M19,0.5l-5,5V15l5-4.5V0.5z"/></g></svg>
                <h3 className="mb-1 font-nacelle text-[1rem] font-semibold text-gray-700 dark:text-gray-200">
                  Literature
                </h3>
                <p className="text-gray-700 dark:text-gray-400">
                Literature occupies an essential role in modern society. It helps us improve our empathy and gain valuable insights, especially stories with deep meaning that resonate with today's world.
                Our online library has countless books in the literature category.
                </p>
              </article>
              <article>
              <svg className="fill-indigo-500"  xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 10v9H4.98V5h9V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2zm-2.94-2.06L17 10l.94-2.06L20 7l-2.06-.94L17 4l-.94 2.06L14 7zM12 8l-1.25 2.75L8 12l2.75 1.25L12 16l1.25-2.75L16 12l-2.75-1.25z"/></svg>
                <h3 className="mb-1 font-nacelle text-[1rem] font-semibold text-gray-700 dark:text-gray-200">
                  Arts
                </h3>
                <p className="text-gray-700 dark:text-gray-400">
                Not only is it an expression of emotion, but art is also a medium for communicating ideas. 
                It gives meaning to our lives and helps us understand our world. In a sentence, it is a way to appreciate life's beauty. Learning art is a good use of time; if that is your goal, the Library of the People has you covered.
                </p>
              </article>
              <article>
              <svg className="fill-indigo-500" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><g><rect fill="none" height="24" width="24"/></g><g><path d="M21,9.02c0,1.09-0.89,1.98-1.98,1.98H18V8.86c1.72-0.44,3-1.99,3-3.84V5l-2,0.02C19,6.11,18.11,7,17.02,7H16.5L12,1L7.5,7 H6.98C5.89,7,5,6.11,5,5.02H3c0,1.86,1.28,3.4,3,3.84V11H4.98C3.89,11,3,10.11,3,9.02H1c0,1.86,1.28,3.4,3,3.84V22h7v-4 c0-0.55,0.45-1,1-1s1,0.45,1,1v4h7v-9.14c1.72-0.44,3-1.99,3-3.84V9L21,9.02z M12,4.33L14,7h-4L12,4.33z M8,9h8v2H8V9z M18,20h-3 v-2c0-1.65-1.35-3-3-3c-1.65,0-3,1.35-3,3v2H6v-7h12V20z"/></g></svg>
                <h3 className="mb-1 font-nacelle text-[1rem] font-semibold text-gray-700 dark:text-gray-200">
                  Religion
                </h3>
                <p className="text-gray-700 dark:text-gray-400">
                Religion has always occupied a significant role in human society. Christianity, Islam, Buddhism, and Hinduism all played an essential role in forming societies' value systems, rules, and practices.
                With the help of our library, get a precious insight into all these world religions.
                </p>
              </article>
              <article>
              <svg className="fill-indigo-500" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-160q75 0 127.5-52.5T660-340q0-75-52.5-127.5T480-520q-75 0-127.5 52.5T300-340q0 75 52.5 127.5T480-160ZM363-572q20-11 42.5-17.5T451-598L350-800H250l113 228Zm234 0 114-228H610l-85 170 19 38q14 4 27 8.5t26 11.5ZM256-208q-17-29-26.5-62.5T220-340q0-36 9.5-69.5T256-472q-42 14-69 49.5T160-340q0 47 27 82.5t69 49.5Zm448 0q42-14 69-49.5t27-82.5q0-47-27-82.5T704-472q17 29 26.5 62.5T740-340q0 36-9.5 69.5T704-208ZM480-80q-40 0-76.5-11.5T336-123q-9 2-18 2.5t-19 .5q-91 0-155-64T80-339q0-87 58-149t143-69L120-880h280l80 160 80-160h280L680-559q85 8 142.5 70T880-340q0 92-64 156t-156 64q-9 0-18.5-.5T623-123q-31 20-67 31.5T480-80Zm0-260ZM363-572 250-800l113 228Zm234 0 114-228-114 228ZM406-230l28-91-74-53h91l29-96 29 96h91l-74 53 28 91-74-56-74 56Z"/></svg>
                <h3 className="mb-1 font-nacelle text-[1rem] font-semibold text-gray-700 dark:text-gray-200">
                  Self-development
                </h3>
                <p className="text-gray-700 dark:text-gray-400">
                A solid belief in your value and what you have to offer is a critical factor in developing or maintaining a solid sense of self. 
                A strong sense of self can allow you to trust your abilities and judgment and maintain self-confidence to set goals and accomplish them. 
                
                </p>
              </article>
              <article>
              <svg className="fill-indigo-500" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M760-400v-260L560-800 360-660v60h-80v-100l280-200 280 200v300h-80ZM560-800Zm20 160h40v-40h-40v40Zm-80 0h40v-40h-40v40Zm80 80h40v-40h-40v40Zm-80 0h40v-40h-40v40ZM280-220l278 76 238-74q-5-9-14.5-15.5T760-240H558q-27 0-43-2t-33-8l-93-31 22-78 81 27q17 5 40 8t68 4q0-11-6.5-21T578-354l-234-86h-64v220ZM40-80v-440h304q7 0 14 1.5t13 3.5l235 87q33 12 53.5 42t20.5 66h80q50 0 85 33t35 87v40L560-60l-280-78v58H40Zm80-80h80v-280h-80v280Z"/></svg>
                <h3 className="mb-1 font-nacelle text-[1rem] font-semibold text-gray-700 dark:text-gray-200">
                  Professions
                </h3>
                <p className="text-gray-700 dark:text-gray-400">
                If you want to advance in your career, continually improving your professional skills is essential. 
                Whether you’re looking to move up in your current company or switch to a new industry, having a solid skill set can make all the difference.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>

    )
}

export default Features;