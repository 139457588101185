import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { CustomCard } from "./BooksMain";


function MilitarySciences() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/books/military-science")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a book in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {



    return (
      <>
        <br /><br />
          <Helmet>
              <meta charSet="utf-8"/>
              <title>Access ebooks in the military sciences category</title>
              <link rel="canonical" href="https://libpeople.com/books/military-science"/>
              <meta name="description" content="Access free ebooks in military science"/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content="Access ebooks in the military sciences category."/>
              <meta property="og:description" content="Access free ebooks in military science."/>
          </Helmet>
          <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href={`/books`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; Books</a>
        </div>
          <div className="w-full justify-center items-center">
              <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
                  <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Military
                      Sciences</b></h2>
              </div>
              <div className="flex justify-center items-center container mx-auto px-2">
                  <p className="text-black-500 dark:text-gray-400">
                      Military and naval sciences encompass the comprehensive study of warfare, defense strategies, military operations, and naval capabilities, including both theoretical principles and practical applications. This field covers various aspects such as military strategy, tactics, logistics, leadership, weapons systems, maritime operations, and national defense policies. It combines historical military knowledge with modern technological advancements to develop effective defense capabilities and strategic planning.

                      These disciplines examine complex aspects of military and naval operations, including combat tactics, force deployment, strategic planning, military intelligence, cyber warfare, and maritime security. They integrate various fields of knowledge, from engineering and technology to psychology and international relations, to understand and improve military effectiveness. The field also addresses crucial aspects of military leadership, organizational management, and decision-making in high-stakes situations.

                      Reading military and naval sciences literature is essential because it provides critical insights into historical conflicts, modern warfare capabilities, and evolving security challenges. These materials offer valuable lessons from past military campaigns, analysis of successful strategies, and understanding of technological innovations in defense systems. They help military professionals and analysts understand modern warfare dynamics, including hybrid threats, cybersecurity challenges, and emerging military technologies. Regular study of this literature enhances strategic thinking, improves tactical decision-making abilities, and provides crucial knowledge for military leadership and planning. This continuous learning is vital in a field where strategic advantages often depend on thorough understanding of both traditional military principles and emerging warfare concepts.


                  </p>

              </div>
              <div className="px-4 py-6 flex justify-center items-center">
                  <input type="text" placeholder="Search by title, author or category"
                         className="input-ghost-primary input w-full max-w-md" value={searchItem}
                         onChange={event => setSearchItem(event.target.value)}></input>
              </div>

              <div className="container px-5 py-5 mx-auto flex flex-wrap">

                  {items.filter((book) => //items here
                  {
                      if (searchItem === "") {
                          return book;
                      } else if
                      (book.Title.toLowerCase().includes(searchItem.toLowerCase()) || book.Author.toLowerCase().includes(searchItem.toLowerCase()) || book.Category.toLowerCase().includes(searchItem.toLowerCase())) {
                          return book;
                      } else return null

                  }).map((book) => //items here

                      <CustomCard key={book.Id}
                                  id={book.Id}
                                  image={book.ImageName}
                                  title={book.Title}
                                  pages={book.Pages}
                                  date={book.Publication}
                                  language={book.Language}
                                  category={book.Category}
                                  author={book.Author}>

                      </CustomCard>)}
              </div>

          </div>


      </>

    );
  }
}

export default MilitarySciences;