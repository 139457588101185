import {React, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
import quizcss from "../../assets/css/quiz.css";

function QuizHeader({id,title,category}) {
return (
  <>
    <Helmet>
      <meta charSet="utf-8"/>
      <title>{title} </title>
      <link rel="canonical" href={`https://libpeople.com/quizzes/quiz/${id}`}/>
      <meta property="og:locale" content="en_US"/>
      <meta property="og:type" content="article"/>
      <meta property="og:title" content={title}/>
    </Helmet>
    <div>
      <div class="px-1 py-4 btn-group btn-group-scrollable">
      <a href={`/quizzes/${category}`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; {category}</a>
        </div>
      <h2 className="flex justify-center items-center px-auto font-semibold  text-xl text-bold  text-sky-500">{title}</h2> 
      <hr className="w-full" /> <br /> 
    </div>
  </>
)
}

function Quiz2 (MyQuestions=[]){
  useEffect(() => {
    var myQuestions = MyQuestions.MyQuestions;
    /*var myQuestions = [
      {
        question: "What is 10/2?",
        answers: {
          a: '3',
          b: '5',
          c: '115'
        },
        correctAnswer: 'b'
      },
      {
        question: "What is 30/3?",
        answers: {
          a: '3',
          b: '5',
          c: '10'
        },
        correctAnswer: 'c'
      }
    ];*/
    
    var quizContainer = document.getElementById('quiz');
    var resultsContainer = document.getElementById('results');
    var submitButton = document.getElementById('submit');
    
    generateQuiz(myQuestions, quizContainer, resultsContainer, submitButton);
    
    function generateQuiz(questions, quizContainer, resultsContainer, submitButton){
    
      function showQuestions(questions, quizContainer){
        // we'll need a place to store the output and the answer choices
        var output = [];
        var answers;
    
        // for each question...
        for(var i=0; i<questions.length; i++){
          
          // first reset the list of answers
          answers = [];
    
          // for each available answer...
          for(let letter in questions[i].answers){
    
            // ...add an html radio button
            answers.push(
              '<label >'
                + '<input type="radio"  name="question'+i+'" value="'+letter+'" style="margin-top:10px;">'
                + ' <b>'+ letter + '</b>) '
                + questions[i].answers[letter]
              + '</label></br>'
            );
          }
    
          // add this question and its answers to the output
          output.push(
            '<div class="question">' +questions[i].id+' . '+ questions[i].question + '</div>'
            + '<div class="answers">' + answers.join('') + '</div>'
          );
        }
    
        // finally combine our output list into one string of html and put it on the page
        quizContainer.innerHTML = output.join('');
      }
    
    
      function showResults(questions, quizContainer, resultsContainer){
        
        // gather answer containers from our quiz
        var answerContainers = quizContainer.querySelectorAll('.answers');
        
        // keep track of user's answers
        var userAnswer = '';
        var numCorrect = 0;

        var fullAnswer = "";

        // for each question...
        for(var i=0; i<questions.length; i++){
    
          
          // find selected answer
          userAnswer = (answerContainers[i].querySelector('input[name=question'+i+']:checked')||{}).value;
          
          // if answer is correct
          if(userAnswer===questions[i].correctAnswer){
            // add to the number of correct answers
            numCorrect++;
            
            // color the answers green
            answerContainers[i].style.color = 'green';
          }
          // if answer is wrong or blank
          else{
            // color the answers red
            answerContainers[i].style.color = 'red';
            fullAnswer += questions[i].id + " -> "+questions[i].correctAnswer +'</br>';
          }
        }
    
        // show number of correct answers out of total
        if(fullAnswer !==""){
          resultsContainer.innerHTML = numCorrect + ' out of ' + questions.length + '</br></br>'
          +"<b>The correct answers are: </b></br>"+fullAnswer;
        }
        else {
          resultsContainer.innerHTML = numCorrect + ' out of ' + questions.length;
        }

      }
    
      // show questions right away
      showQuestions(questions, quizContainer);
      
      // on submit, show results
      submitButton.onclick = function(){
        showResults(questions, quizContainer, resultsContainer);
      }
    
    }

    }, [])// <-- }, [])  empty array means 'run once'
    return(
      <>
        <div className="px-2 py-2">
          <div id="quiz" ></div>
          <button id="submit" className="btn btn-secondary">Get Results</button>
          <div id="results" ></div>
        </div>
      </>



      
      );




}

function QuizDisplay (){
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
    // Get a specific parameter
    let { id } = useParams();

    useEffect(() => {
      const fileID = parseInt(id);
      const url = `https://goldfish-app-asx3v.ondigitalocean.app/quizzes/${fileID}`;
      fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
 
      }, [id])// <-- }, [])  empty array means 'run once'






      if (error) {
        return <div><br /><br /><br />Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div><br /><br /><br />Loading...</div>;
      } else {
      return(
        <>
        <br />
        <section className="body-font text-base text-black-500 dark:text-white ">
          <div className="px-5 pt-12 mx-auto">
            <QuizHeader  
                key={items.Id}
                id={items.Id}
                category={items.Category}
                title={items.Title} >
            </QuizHeader>
          </div>
          <div className="px-2 py-2">
          {
            <Quiz2 
              key={items.Id}
              id={items.Id}  
              MyQuestions={items.MyQuestions} >
            </Quiz2>
          }


              </div>


      </section> 
  
        </>



        
        );}




}

export default QuizDisplay;