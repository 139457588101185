import React, {useState} from "react";
//import HomeFooter from "./HomeFooter";
import librarian from "../assets/images/young-librarian.jpg";
import bookshelf from "../assets/images/old-bookshelf.jpg";
import roadmap from "../assets/images/roadmap.jpg";
import contact from "../assets/images/contactus.jpg";
import Hero from "./landing/Hero";
import Hero2 from "./landing/Hero2";
import WorkFlows from "./landing/WorkFlows";
import Features from "./landing/Features";
import Cta from "./landing/Cta";
import Footer from "./landing/Footer";
import {Helmet} from "react-helmet";
function Home() {

  //const library = `url("${librarian}")`;
  //const book = `url("${bookshelf}")`;


  return (
    <div className="h-screen" >

      <Helmet>
        <meta charSet="utf-8"/>
        <title>Library of the People Home page</title>
        <link rel="canonical" href="https://libpeople.com"/>
        <meta name="description"
              content="Library of the People is your all-in-one learning platform. Instantly get access to thousands of books, tutorials, and quizzes."/>
        <meta name="keywords"
              content="library,books,quizzes,tutorials,utilities,rhymes finder,dictionaries,summarizer"/>
        <meta property="og:title" content="Library of the People Home page"/>
        <meta property="og:description"
              content="Library of the People is your all-in-one learning platform. Instantly get access to thousands of books, tutorials, and quizzes."/>
        <meta property="og:site_name" content="Library of the People Home page"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="article"/>
      </Helmet>

      <Hero/>
      <WorkFlows/>


      <Hero2/>

        <Features />

        <Cta />  

        <Footer />

    </div>

  );
}
export default Home;