import {React, useState} from "react";
import {Helmet} from "react-helmet";
import {EnFr} from "./Enfr";
import EnEs from "./Enes";
import EnPt from "./Enpt";
import FrEn from "./Fren";
import FrEs from "./Fres";
import FrPt from "./Frpt";




const tabItems = [
  {
    id: 1,
    title: 'English - > French',
    content: <EnFr></EnFr>,
  },
  {
    id: 2,
    title: 'English - > Spanish',
    content: <EnEs></EnEs>,
  },
  {
    id: 3,
    title: 'English - > Portuguese',
    content: <EnPt></EnPt>,
  },
  {
    id: 4,
    title: 'Français - > Anglais',
    content: <FrEn></FrEn>,
  },
  {
    id: 5,
    title: 'Français - > Espagnol',
    content: <FrEs></FrEs>,
  },
  {
    id: 6,
    title: 'Français - > Portuguais',
    content: <FrPt></FrPt>,
  },
];

const TabItemComponent = ({
    title = '',
    onItemClicked = () => console.error('You passed no action to the component'),
    isActive = false,
  }) => {
    return (
        <>
            <div className={isActive ? 'tabitem' : 'tabitem tabitem--inactive'} onClick={onItemClicked}>
                <div className="">
                <button className={isActive ? 'btn btn-rounded text-white bg-blue-500 hover:bg-blue-700 btn-sm m-1' : 'btn btn-rounded btn-solid-primary btn-sm m-1'}>{title}</button>
                </div>
            </div>
        </>

    )
  };


const Dictionaries= () => {
 const [active, setActive] = useState(0);
  
  return (

     <div className="wrapper py-12">

         <Helmet>
             <meta charSet="utf-8"/>
             <title>Bilingual Dictionaries</title>
             <link rel="canonical" href="https://libpeople.com/utilities/dictionaries"/>
             <meta name="description" content="English, French, Spanish and Portuguese bilingual dictionaries"/>
             <meta name="keywords" content="dictionary,bilingual dictionaries"/>
             <meta property="og:title" content="Bilingual Dictionaries"/>
             <meta property="og:description" content="English, French, Spanish and Portuguese bilingual dictionaries"/>
             <meta property="og:site_name" content="Bilingual Dictionaries"/>
             <meta property="og:locale" content="en_US"/>
             <meta property="og:type" content="article"/>
         </Helmet>


         {(active === 0) && <div className="py-2 flex justify-center items-center  bg-base-100 mainDiv" >
          <div className="py-4 lg:py-6">
            <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
              <h2 className="flex justify-center items-center  dark:text-sky-500 "><b>Bilingual Dictionaries</b></h2>
                <p className="text-black-500 dark:text-white">
                Our bilingual dictionaries are the go-to tool that tourists, travelers, and language enthusiasts need to grasp the bases
                of one of the languages quickly.
                 You can learn the most common expressions, sentences, and words. 
                 Each dictionary gathers around two thousand words and phrases. 
                 Moreover, you can hear the pronunciations of all the words, sentences, and expressions.
                 Select one of the dictionaries below to start your learning journey!
                </p>
            </div>
          </div>
        </div>}
        {(active !== 0) && <div className="py-2 flex justify-center items-center  bg-base-100 mainDiv" >
          <div className="py-4 lg:py-6">
            <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
              <h2 className="flex justify-center items-center  dark:text-sky-500 "><b>Dictionaries</b></h2>
            </div>
          </div>
        </div>}



        <div className="flex justify-center items-center px-2">
          <div className="tabs">
            {
            tabItems.map(item =><TabItemComponent
                key={item.id}
                title={item.title}
                onItemClicked={() => setActive(item.id)}
                isActive={active === item.id}
                />
            )}
          </div>
        </div>

      <div className="content flex flex-wrap justify-center items-center">
        {tabItems.map(item => {
          return active === item.id ? item.content : ''
        })}
      </div>
     </div>
  )
}

export  default Dictionaries;