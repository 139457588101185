import React, {useState} from "react";
//import HomeFooter from "./HomeFooter";
import librarian from "../../assets/images/young-librarian.jpg";
import bookshelf from "../../assets/images/old-bookshelf.jpg";
import roadmap from "../../assets/images/roadmap.jpg";
import contact from "../../assets/images/contactus.jpg";


  

export default function Hero2 (){
    return (
<section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-4 py-4 md:flex-row flex-col items-center">
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img className="object-cover object-center rounded" alt="hero" src={bookshelf}/>
    </div>
    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
    <div
        className="animate-[gradient_6s_linear_infinite]  pb-5 font-nacelle text-xl font-semibold text-transparent md:text-2xl"
        data-aos="fade-up">
          <h2><span className="text-indigo-500">Free Education</span></h2>
                
    </div>
      <p className="mb-8 leading-relaxed text-gray-500 dark:text-gray-400 ">Library of the people is our contribution to the fight for free education worldwid.</p>
      <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center w-full">
        <div data-aos="fade-up" data-aos-delay="400">
        <a className="btn text-white bg-sky-600 hover:bg-sky-700 w-full mb-4 sm:w-auto sm:mb-0" href="/contact">Contact us</a>
        </div>
        <div data-aos="fade-up" data-aos-delay="600">
        <a className="btn text-white bg-gray-700 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="/about">Learn more</a>
        </div>
    </div>
    </div>
  </div>
</section>
    )
}