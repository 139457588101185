import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { CustomCard } from "./BooksMain";







//for fectch
//https://legacy.reactjs.org/docs/faq-ajax.html
//for search in fetched json
//

function FormalSciences() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);


  //const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/books/formal-science")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])


  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a book in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {
    return (
      <>
        <br /><br />
          <Helmet>
              <meta charSet="utf-8"/>
              <title>Access ebooks in the formal sciences category</title>
              <link rel="canonical" href="https://libpeople.com/books/formal-science"/>
              <meta name="description" content="Access free ebooks in mathematics and statistics"/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content="Access ebooks in the formal sciences category."/>
              <meta property="og:description" content="Access free ebooks in mathematics and statistics."/>
          </Helmet>
          <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href={`/books`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; Books</a>
        </div>
        <div className="w-full justify-center items-center">
            <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
                <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Formal Sciences</b></h2>
            </div>
            <div className="flex justify-center items-center container mx-auto px-2">
              <p  className="text-black-500 dark:text-gray-400">
              Books in this category consist of the following subcategories: 
            <span className="font-bold text-sky-500 px-1">Mathematics</span>, 
            and 
            <span className="font-bold text-sky-500 px-1">Statistics</span>. Formal sciences represent a unique branch of knowledge that focuses on abstract systems and formal languages, encompassing disciplines such as mathematics, logic, theoretical computer science, and statistics. Unlike natural sciences, which study physical phenomena, formal sciences deal with abstract structures and relationships, creating theoretical frameworks that can be applied across various fields of study and real-world scenarios.

                  These disciplines are distinguished by their use of formal logic, rigorous proof methods, and abstract reasoning to establish absolute truths within their defined systems. They provide the foundational tools and methodologies necessary for understanding complex patterns, relationships, and structures that exist independently of physical reality. Mathematics, for instance, creates abstract frameworks that can model everything from financial markets to quantum mechanics, while logic provides the basis for valid reasoning and computer programming.

                  Reading formal science literature is crucial because it develops critical thinking skills, analytical capabilities, and abstract reasoning abilities. These texts help readers understand the precise language and methodologies used to construct reliable proofs and arguments. They provide the theoretical foundations necessary for advancing technology, solving complex problems, and making breakthrough discoveries in other scientific fields. Moreover, studying formal sciences enhances one's ability to think systematically, recognize patterns, and approach problems with logical precision – skills that are invaluable in both academic pursuits and practical applications across numerous professional fields.
              </p>

            </div>
            <div className="px-4 py-6 flex justify-center items-center">
              <input  type="text" placeholder="Search by title, author or category" className="input-ghost-primary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>
          <div className="container px-5 py-5 mx-auto flex flex-wrap" >
            {
              items.filter((book) => //items here
              {
                if(searchItem === "") {
                  return book;
                }
                else if 
                (book.Title.toLowerCase().includes(searchItem.toLowerCase()) || book.Author.toLowerCase().includes(searchItem.toLowerCase()) || book.Category.toLowerCase().includes(searchItem.toLowerCase())) {
                  return book;
                }
                else return null
                
              }).map((book) => //items here

              <CustomCard key={book.Id} 
              id = {book.Id}
              image = {book.ImageName}
              title={book.Title} 
              language={book.Language} 
              pages={book.Pages}
              date={book.Publication}
              category={book.Category} 
                author={book.Author}  >
            
            </CustomCard>)
            }
          </div>


        </div>

      
      </>

    );
  }
}









  
  export {CustomCard, FormalSciences};