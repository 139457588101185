import React from "react";
import {Helmet} from "react-helmet";
function About() {
  return (
    <>
    <br /><br />

        <Helmet>
            <meta charSet="utf-8"/>
            <title>About us</title>
            <link rel="canonical" href="https://libpeople.com/about"/>
            <meta name="description" content="Why we built the Library of the People website."/>
            <meta name="keywords" content="about us"/>
            <meta property="og:title" content="About us"/>
            <meta property="og:description" content="Why we built the Library of the People website."/>
            <meta property="og:site_name" content="About us"/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="article"/>
        </Helmet>


        <div className="py-6 sm:py-12">
            <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
          <div className="mx-auto max-w-md px-4">
            <h2><b> About Us</b></h2>
            <div className="divide-y divide-gray-300/50">
              <div className="space-y-6 py-8 text-base leading-7 text-gray-600 dark:text-white">
                <p> <span className="font-bold text-blue-500">Library of the People </span>  is a Professional all-in-one learning Platform. 
                  It provides you with exciting content that you will enjoy very much. 
                <b>We </b> are committed to providing you with the best educational content, such as books, quizzes, and tutorials, 
                  with a focus on reliability. <b>We </b> will keep adding quality content. But, who are <b>We </b> ?</p>
                <p> 
                  We, refers to <a className="text-blue-400 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">Arfusoft</a> , our software development company that designed and implemented this website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
  }
  export default About;