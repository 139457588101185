import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { CustomCard } from "./BooksMain";


function SocialSciences() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/books/social-science")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a book in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {



    return (
      <>
        <br /><br />
          <Helmet>
              <meta charSet="utf-8"/>
              <title>Access ebooks in the social sciences category</title>
              <link rel="canonical" href="https://libpeople.com/books/social-science"/>
              <meta name="description"
                    content="Access free ebooks in law, psychology, geography, history, economy, finance, etc"/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content="Access ebooks in the social sciences category."/>
              <meta property="og:description"
                    content="Access free ebooks in law, psychology, geography, history, economy, finance, etc."/>
          </Helmet>
          <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href={`/books`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; Books</a>
        </div>
        <div className="w-full justify-center items-center">
        <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
                <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Social Sciences</b></h2>
            </div>
            <div className="flex justify-center items-center container mx-auto px-2">
            <p  className="text-black-500 dark:text-gray-400">
              <span>Books in this category consist of the following subcategories:</span> 
              <span className="font-bold text-sky-500 px-1">Law, Psychology, Political science, Geography, Anthropology,
                History, Philosophy, Economy, Archaelogy, Sociology, Finance  </span>. Social sciences encompass a broad range of disciplines that study human behavior, societies, and social relationships. These disciplines include sociology, anthropology, political science, economics, psychology, and others, each focusing on different aspects of human interactions and institutions. Social sciences aim to understand how individuals and groups operate within societies, the structures and processes that govern behavior, and the complex dynamics that shape cultural, economic, and political systems.

                Reading books on social sciences is essential because they provide valuable insights into the functioning of societies and the underlying causes of social phenomena. These books offer theoretical frameworks and empirical research that help explain social patterns, behaviors, and institutions. They foster critical thinking and analytical skills, enabling readers to better understand and address societal challenges. Additionally, studying social sciences promotes empathy and cultural awareness, encouraging individuals to appreciate diverse perspectives and the intricacies of human interactions. By delving into social science literature, readers can engage more effectively in public discourse, make informed decisions, and contribute to the development of more equitable and sustainable societies. Thus, books on social sciences are crucial for personal growth and the collective well-being of society.
            
            </p> 
            </div>
            <div className="px-4 py-6 flex justify-center items-center">
              <input  type="text" placeholder="Search by title, author or category" className="input-ghost-primary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>

    <div className="container px-5 py-5 mx-auto flex flex-wrap">

    {items.filter((book) => //items here
              {
                if(searchItem === "") {
                  return book;
                }
                else if 
                (book.Title.toLowerCase().includes(searchItem.toLowerCase()) || book.Author.toLowerCase().includes(searchItem.toLowerCase()) || book.Category.toLowerCase().includes(searchItem.toLowerCase())) {
                  return book;
                }
                else return null
                
              }).map((book) => //items here

              <CustomCard key={book.Id} 
              id = {book.Id}
              image = {book.ImageName}
              title={book.Title} 
              pages={book.Pages}
              date={book.Publication}
              language={book.Language} 
              category={book.Category} 
                author={book.Author}  >
            
            </CustomCard>)}
    </div>

        </div>

      
      </>

    );}
  }
  export default SocialSciences;