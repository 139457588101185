import React from "react";

export default function Donate(){


    return (
<div className="">
  <section id="login" className="p-4 flex flex-col justify-center min-h-screen max-w-md mx-auto">

    <div className="p-6 rounded card">
        <div className="flex items-center justify-center font-black m-3 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mr-3 text-red-600 animate-pulse" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
            </svg>
            <h1 className="tracking-wide text-2xl text-gray-900 dark:text-gray-400">Buy Me a Coffee</h1>
        </div>

        <div className="mb-12 m-3">
        <p>Your donation can help change lives. Contribute to our cause and make a positive impact on the world.</p>
        </div>
        <form id="login_form" className="flex flex-col justify-center">

        <label className="text-sm font-medium">$</label>
          <input className="mb-3 px-2 py-1.5
          mb-3 mt-1 block w-full px-2 py-1.5 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 
          focus:outline-none
          focus:border-sky-500
          focus:ring-1
          focus:ring-sky-500
          focus:invalid:border-red-500 focus:invalid:ring-red-500  bg-transparent ring-1 " type="text" name="amount" placeholder="Amount in USD"/>


          <label className="text-sm font-medium">From</label>
          <input className="mb-3 px-2 py-1.5
          mb-3 mt-1 block w-full px-2 py-1.5 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
          focus:outline-none
          focus:border-sky-500
          focus:ring-1
          focus:ring-sky-500
          focus:invalid:border-red-500 focus:invalid:ring-red-500 bg-transparent  ring-1 " type="text" name="username" placeholder="Name"/>
          <label className="text-sm font-medium">Messages (optional)</label>
          <textarea className="
          mb-3 mt-1 block w-full px-2 py-1.5 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
          focus:outline-none
          focus:border-sky-500
          focus:ring-1
          focus:ring-sky-500
          focus:invalid:border-red-500 focus:invalid:ring-red-500 bg-transparent  ring-1  " name="messages" placeholder="Write something"></textarea>
          <button className="px-4 py-1.5 rounded-md shadow-lg bg-gradient-to-r from-sky-600 to-indigo-600 font-medium text-gray-100 block transition duration-300" type="submit">
            <span id="login_process_state" className="hidden">Sending :</span>
            <span id="login_default_state">Donate<span id="subtotal"></span></span>
          </button>
        </form>
    </div>
  </section>
</div>
    )
}