import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';


function TutorialCard({title, id}){
  return (
    <>
      <div className="p-4 md:w-1/3 lg:w-1/3 w-full  text-gray-900 dark:text-white">
        <div className="flex rounded-lg h-full  bg-gray-100 dark:bg-slate-800  p-8 flex-col">
          <div className="flex items-center mb-3">
            <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-700 text-white flex-shrink-0">
            <ion-icon name="pencil-outline"></ion-icon>

            </div>
            <h1 className="text-base title-font font-medium">{title}</h1>
          </div>
          <div className="flex-grow">
            <a href={"/tutorials/tutorial/"+id} className="mt-3 text-indigo-500 inline-flex items-center font-bold">Read Tutorial
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}



function TutorialsCategory() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

    // Get a specific parameter
    let { category } = useParams();

  useEffect(() => {
    fetch(`https://goldfish-app-asx3v.ondigitalocean.app/tutorials/category/${category}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null  || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a tutorial in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {


    return (
      <>
        <br /><br />
          <Helmet>
              <meta charSet="utf-8"/>
              <title>{`${category} tutorials`} </title>
              <link rel="canonical" href={`https://libpeople.com/tutorials/${category}`}/>
              <meta name="description" content={`Access thousands of detailed ${category} tutorials`}/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content={`${category} tutorials`}/>
          </Helmet>


          <div class="mt-6 px-4 btn-group btn-group-scrollable">
          <a href="/tutorials" class="btn  btn-ghost px-4 text-sm text-sky-600"><ion-icon name="arrow-back"></ion-icon>&emsp; Tutorials</a>
        </div>
        <div className="w-full justify-center items-center">

            <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
            <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>{category}</b></h2>
            </div>

            {(category == "C++") && 

              <div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
                <div className="container px-2 mx-auto py-2 lg:py-4">
                    <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

                        <p> 
                        C++ is a high-performance programming language that builds on the foundation of C, offering object-oriented features and enhanced capabilities. Developed by Bjarne Stroustrup at Bell Labs in the early 1980s, C++ was initially designed to improve productivity by providing efficient and flexible language features suitable for system-level and application-level programming.
 <br />  <b> History</b>
: C++ emerged from the need for a language that combined the low-level hardware control of C with the high-level features required for larger, more complex applications. Stroustrup introduced classes, which allowed for object-oriented programming—a paradigm that simplifies the design and management of large software projects.
<br /><b> Use Cases</b>
: C++ is widely used in various domains due to its performance and versatility. In system/software development, it is employed for building operating systems, drivers, and embedded systems. In game development, C++ is a go-to language for creating graphics-intensive, real-time applications thanks to its ability to handle complex algorithms and direct hardware manipulation. Additionally, C++ is utilized in developing high-performance applications like web browsers (e.g., Chrome), financial systems, and enterprise software. Its standard library and robust community support make it a reliable choice for both novice and experienced programmers.

C++ remains a foundational language in computer science education and a critical tool for industries requiring efficient, scalable, and high-performance solutions.
                        </p>
                    </div>
                </div>
              </div>
            }

{(category == "Python") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Python is a versatile, high-level programming language known for its simplicity and readability, making it accessible to both beginners and experienced developers. Created by Guido van Rossum and first released in 1991, Python was designed to emphasize code readability and ease of use, which has contributed to its widespread adoption and popularity.
          <br />  <b> History</b>: Python's development began in the late 1980s, and it was intended to be a successor to the ABC language, which had similar goals of simplicity and ease of learning. Van Rossum's vision was to create a language that prioritized programmer productivity and code readability, leading to Python's clean and straightforward syntax.
<br /><b> Use Cases</b>: Python's flexibility and extensive standard library make it suitable for a wide range of applications. It is widely used in web development, with frameworks like Django and Flask streamlining the creation of robust, scalable web applications. In data science and machine learning, Python is a dominant language, with libraries such as NumPy, pandas, and TensorFlow enabling complex data analysis and model building. Python also excels in automation, scripting, and prototyping, allowing developers to quickly test ideas and build tools. Furthermore, its use in academia and education continues to grow, as it serves as an excellent introductory programming language.

Python's ease of learning, coupled with its powerful capabilities, has solidified its status as a leading language in both industry and education.
          </p>
      </div>
  </div>
</div>
}

{(category == "Csharp") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          C# (pronounced "C-sharp") is a modern, object-oriented programming language developed by Microsoft as part of its .NET initiative. First introduced in 2000, C# was designed by Anders Hejlsberg to provide a versatile and efficient language for building a wide range of applications, from desktop to web to mobile.
          <br />  <b> History</b>: C# was created to address the limitations of existing languages, combining the power and flexibility of C++ with the simplicity and ease of use of Visual Basic. Its design was heavily influenced by Java and C++, incorporating features such as strong type checking, garbage collection, and support for modern programming paradigms.
<br /><b> Use Cases</b>: C# is widely used in enterprise environments due to its integration with the .NET framework, which provides a comprehensive library of pre-built functionalities. It is a popular choice for developing Windows applications, particularly with Windows Forms and WPF (Windows Presentation Foundation). C# is also extensively used in web development, leveraging frameworks like ASP.NETto create dynamic, scalable web applications.

In recent years, C# has expanded into mobile and game development. Xamarin, a cross-platform framework, enables C# developers to create apps for iOS and Android. Unity, one of the leading game engines, uses C# as its primary scripting language, making it a preferred choice for game developers.

C#'s robust features, combined with Microsoft's continued support and the extensive .NET ecosystem, make it a powerful and versatile language for a wide array of development projects.
          </p>
      </div>
  </div>
</div>
}

{(category == "Java") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Java is a versatile, platform-independent programming language widely used for building scalable and robust applications. Developed by James Gosling and his team at Sun Microsystems (now part of Oracle Corporation), Java was first released in 1995. Its primary goal was to offer a "write once, run anywhere" capability, enabling code to run on any device equipped with a Java Virtual Machine (JVM).
          <br />  <b> History</b>: Java's development began in the early 1990s as part of the Green Project, which aimed to create software for electronic devices. The language was initially called Oak, later renamed Java after the Indonesian coffee. Its release in 1995 revolutionized programming by introducing concepts like platform independence, object-oriented programming, and automated garbage collection, setting new standards for software development.
<br /><b> Use Cases</b>: Java is widely used across various domains due to its reliability and performance. It powers enterprise-level applications with frameworks like Spring, enabling the development of secure, high-performance systems. In web development, Java provides robust backend solutions through servlets, JSP (JavaServer Pages), and Spring Boot. Android app development relies heavily on Java, making it a cornerstone for creating mobile applications.

Additionally, Java is crucial in big data technologies, with tools like Apache Hadoop and Apache Spark using Java for processing vast datasets. It also plays a significant role in scientific computing, financial services, and e-commerce platforms, showcasing its versatility and enduring relevance in the tech industry.

Java's extensive ecosystem, robust libraries, and community support continue to make it a powerful language for modern software development.
          </p>
      </div>
  </div>
</div>
}



{(category == "Kotlin") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Kotlin is a modern, statically-typed programming language developed by JetBrains, designed to interoperate fully with Java while offering enhanced features and a more concise syntax. Officially released in 2011, Kotlin aims to address some of Java's limitations, providing a more expressive and safer programming experience.
          <br />  <b> History</b>: Kotlin's development began in 2010, with JetBrains seeking to create a language that could be more productive and enjoyable to use than Java, while maintaining full compatibility with the Java ecosystem. In 2017, Kotlin received significant recognition when Google announced it as an official language for Android app development, further boosting its popularity.
<br /><b> Use Cases</b>: Kotlin is predominantly used for Android development, offering seamless interoperability with existing Java code and access to a rich set of libraries. Its concise syntax and modern language features, such as null safety and extension functions, make Android development more efficient and error-free. Kotlin is also suitable for server-side development, with frameworks like Ktor enabling the creation of robust, scalable web applications.
Moreover, Kotlin is gaining traction in data science and scripting, thanks to its concise syntax and powerful features. It's also utilized in developing desktop applications and is compatible with numerous platforms, including JavaScript and native code.

Kotlin's blend of expressiveness, safety, and full Java interoperability makes it a versatile and powerful language for a variety of programming tasks.
          </p>
      </div>
  </div>
</div>
}

{(category == "Free Pascal") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Free Pascal is a robust, open-source compiler for the Pascal programming language, designed to support multiple architectures and operating systems. It was developed by Florian Paul Klämpfl and first released in 1993, aiming to provide a reliable and efficient Pascal compiler that is compatible with Turbo Pascal and Delphi.
          <br />  <b> History</b>: The development of Free Pascal started in 1992, with the goal of creating a free and versatile Pascal compiler that could operate on various platforms. Over the years, Free Pascal has evolved significantly, incorporating modern features and maintaining compatibility with legacy Pascal code.
<br /><b> Use Cases</b>: Free Pascal is widely used in academia for teaching programming concepts due to its simplicity and strong type-checking features, which help prevent common programming errors. It's also employed in developing cross-platform applications, thanks to its support for numerous operating systems, including Windows, Linux, macOS, and various Unix derivatives. Additionally, Free Pascal is used in embedded systems programming, providing a reliable tool for developing software for microcontrollers and other embedded devices.
The combination of its open-source nature, extensive compatibility, and support for modern programming features makes Free Pascal a valuable tool for both educational purposes and practical software development. Its versatility ensures it remains relevant across various fields and applications, fostering continued interest and usage in the programming community.
          </p>
      </div>
  </div>
</div>
}

{(category == "Ada") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Ada is a high-level, statically typed programming language designed for reliability and efficiency, particularly in systems where safety and security are paramount. Developed in the early 1980s by Jean Ichbiah and his team under a U.S. Department of Defense contract, Ada was created to address the need for a standardized language for embedded and mission-critical applications.
          <br />  <b> History</b>: The language was named after Ada Lovelace, who is often regarded as the first computer programmer. Ada was intended to replace the numerous programming languages then used by the Department of Defense with a single, consistent language. The first version, Ada 83, was standardized by ANSI in 1983, with subsequent updates leading to Ada 95, Ada 2005, and Ada 2012, each enhancing the language’s capabilities.
<br /><b> Use Cases</b>: Ada is renowned for its strong typing, modularity, run-time checking, and concurrency features, making it ideal for high-integrity systems. It is extensively used in aerospace, defense, and transportation industries for developing systems that require high levels of reliability and real-time performance. Examples include avionics software, air traffic control systems, railway signaling, and critical control systems in nuclear power plants. Ada’s design also supports the development of large, complex applications with stringent requirements for safety and security, ensuring robust and error-free software.

Ada’s enduring emphasis on safety and reliability continues to make it a preferred choice for applications where failure is not an option.
          </p>
      </div>
  </div>
</div>
}

{(category == "Swift") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Swift is a powerful and intuitive programming language developed by Apple, designed for building applications across Apple's ecosystem, including iOS, macOS, watchOS, and tvOS. Introduced in 2014, Swift aims to provide a modern alternative to Objective-C, offering a more concise and safer programming experience.
          <br />  <b> History</b>: Swift's development began in 2010 under the leadership of Chris Lattner and was publicly introduced at Apple's Worldwide Developers Conference (WWDC) in 2014. Swift was designed to be easy to learn and use, incorporating modern language features and a syntax that encourages safe programming patterns.
<br /><b> Use Cases</b>: Swift is primarily used for developing applications for Apple devices. It boasts a modern syntax that is easy to read and write, making it accessible to beginners and powerful for seasoned developers. Swift's safety features, such as optionals and type inference, help prevent common programming errors, enhancing code reliability.

Swift also supports a wide range of programming paradigms, including object-oriented and functional programming, making it versatile for various types of software development. With the introduction of SwiftUI, a framework for building user interfaces, Swift has further streamlined the development process, allowing developers to create interactive and visually appealing applications with less code.

Swift's combination of performance, safety, and ease of use makes it a preferred language for iOS and macOS app development, contributing to the rich and vibrant ecosystem of applications available on Apple platforms.
          </p>
      </div>
  </div>
</div>
}



{(category == "Dart") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Dart is a client-optimized programming language developed by Google, designed for building fast, modern web and mobile applications. First announced in 2011, Dart was created to address the limitations of JavaScript, offering a more structured and efficient language for web development.
          <br />  <b> History</b>: Dart's development began as an internal project at Google to create a language that could improve upon JavaScript's performance and scalability. Its initial release aimed to provide a robust alternative for client-side development. Over the years, Dart has evolved significantly, with the introduction of the Flutter framework in 2017, further boosting its popularity for cross-platform mobile app development.
<br /><b> Use Cases</b>: Dart is widely used in conjunction with Flutter for developing mobile applications that run on both Android and iOS from a single codebase. Its clean syntax, strong typing, and comprehensive libraries make it an excellent choice for building high-performance, visually appealing mobile apps. Dart is also used for web development, offering a productive environment for creating complex web applications with frameworks like AngularDart.

Dart's ability to compile to native code ensures fast execution and responsiveness, making it suitable for building desktop applications as well. Its integration with the Flutter framework allows developers to create natively compiled applications for mobile, web, and desktop from a single codebase, demonstrating Dart's versatility and efficiency in modern app development.
          </p>
      </div>
  </div>
</div>
}

{(category == "Go") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          <br />  <b> History</b>:
<br /><b> Use Cases</b>:
          </p>
      </div>
  </div>
</div>
}
{(category == "Rust") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          <br />  <b> History</b>:
<br /><b> Use Cases</b>:
          </p>
      </div>
  </div>
</div>
}
{(category == "Javascript") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          <br />  <b> History</b>:
<br /><b> Use Cases</b>:
          </p>
      </div>
  </div>
</div>
}


{(category == "Zig") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          <br />  <b> History</b>:
<br /><b> Use Cases</b>:
          </p>
      </div>
  </div>
</div>
}

{(category == "Gleam") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          <br />  <b> History</b>:
<br /><b> Use Cases</b>:
          </p>
      </div>
  </div>
</div>
}
{(category == "Ocaml") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          <br />  <b> History</b>:
<br /><b> Use Cases</b>:
          </p>
      </div>
  </div>
</div>
}

{(category == "Elixir") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          <br />  <b> History</b>:
<br /><b> Use Cases</b>:
          </p>
      </div>
  </div>
</div>
}



            <div className="px-6 py-6 flex justify-center items-center">
              <input  type="text" placeholder="Search by title" className="input-ghost-secondary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>

    <div className="container px-5 py-5 mx-auto flex flex-wrap">

    {items.filter((quiz) => //items here
              {
                if(searchItem === "") {
                  return quiz;
                }
                else if 
                (quiz.Title.toLowerCase().includes(searchItem.toLowerCase()) ) {
                  return quiz;
                }
                else return null
                
              }).map(quiz => 
          <TutorialCard key={quiz.Id} 
          id = {quiz.Id}
          title={quiz.Title}  >

        </TutorialCard>)}
    </div>


        </div>

      
      </>

    );}
  }
  export default TutorialsCategory;