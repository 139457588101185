import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { CustomCard } from "./BooksMain";


function PerformingArt() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/books/performing-art")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a book in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {



    return (
      <>
        <br /><br />
          <Helmet>
              <meta charSet="utf-8"/>
              <title>Access ebooks in the performing arts category</title>
              <link rel="canonical" href="https://libpeople.com/books/performing-art"/>
              <meta name="description" content="Access free ebooks in music, dance, film and theatre"/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content="Access ebooks in the performing arts category."/>
              <meta property="og:description" content="Access free ebooks in music, dance, film and theatre."/>
          </Helmet>
          <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href={`/books`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; Books</a>
        </div>
        <div className="w-full justify-center items-center">
        <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
                <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Performing Arts</b></h2>
            </div>
            <div className="flex justify-center items-center container mx-auto px-2">
            <p  className="text-black-500 dark:text-gray-400">
              <span>Books in this category consist of the following subcategories:</span> 
              <span className="font-bold text-sky-500 px-1">Music, Dance, 
              Film, 
              Theatre </span>. Performing arts encompass the vibrant disciplines of dance, music, theater, and other forms of live artistic expression where artists use their bodies, voices, and creative skills to communicate emotions, stories, and ideas to audiences. This dynamic field combines technical expertise with artistic interpretation, requiring performers to master both physical skills and emotional expression while engaging in real-time interaction with their audience.

                The discipline integrates various elements including choreography, musical composition, dramatic interpretation, staging, and performance techniques. Performers must develop their craft through rigorous training, regular practice, and deep understanding of their chosen art form's traditions and innovations. The field also encompasses technical aspects such as lighting, sound design, costume creation, and stage management, all working in harmony to create compelling live performances.

                Reading about performing arts is essential because it provides crucial insights into performance techniques, artistic interpretation, historical context, and evolving trends in the field. These materials offer valuable knowledge about different performance styles, methodologies, and artistic approaches across cultures and time periods. They help performers and enthusiasts understand the theoretical foundations of their craft, learn from master artists' experiences, and stay current with contemporary developments in the field. Regular study of performing arts literature enhances artistic appreciation, technical understanding, and creative interpretation while preserving cultural heritage and fostering innovation. This knowledge is vital for both practitioners seeking to perfect their craft and audiences wanting to deepen their appreciation of live performance, ensuring the continued evolution and vitality of these timeless art forms.
             
              
            </p> 
            </div>
            <div className="px-4 py-6 flex justify-center items-center">
              <input  type="text" placeholder="Search by title, author or category" className="input-ghost-primary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>

    <div className="container px-5 py-5 mx-auto flex flex-wrap">

    {items.filter((book) => //items here
              {
                if(searchItem === "") {
                  return book;
                }
                else if 
                (book.Title.toLowerCase().includes(searchItem.toLowerCase()) || book.Author.toLowerCase().includes(searchItem.toLowerCase()) || book.Category.toLowerCase().includes(searchItem.toLowerCase())) {
                  return book;
                }
                else return null
                
              }).map((book) => //items here

              <CustomCard key={book.Id} 
              id = {book.Id}
              image = {book.ImageName}
              title={book.Title} 
              pages={book.Pages}
              date={book.Publication}
              language={book.Language} 
              category={book.Category} 
                author={book.Author}  >
            
            </CustomCard>)}
    </div>

        </div>

      
      </>

    );}
  }
  export default PerformingArt;