import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import About from "./components/About";
import {BooksMain} from "./components/bookcomponents/BooksMain";
import QuizzesMain from "./components/quizzescomponents/QuizzesMain";
import TutorialsMain from "./components/tutorialscomponents/TutorialsMain";
import Summarizer from "./components/utilities/Summarizer";
import Dictionaries from "./components/utilities/Dictionaries";
import EnDictionary from "./components/utilities/EnDictionary";
import RhymesFinder from "./components/utilities/RhymesFinder";
import Policy from "./components/Policy";
import Term from "./components/Term";
import Permissions from "./components/Permissions";
import Donate from "./components/Donate";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ResetPass from "./components/ResetPass";
import VisualArt from "./components/bookcomponents/VisualArt";
import Literature from "./components/bookcomponents/Literature";
import Religion from "./components/bookcomponents/Religion";
import Other from "./components/bookcomponents/Other";
import { FormalSciences } from "./components/bookcomponents/FormalSciences";
import SocialSciences from "./components/bookcomponents/SocialSciences";
import NaturalSciences from "./components/bookcomponents/NaturalSciences";
import PerformingArt from "./components/bookcomponents/PerformingArts";
import Engineering from "./components/bookcomponents/Engineering";
import HealthSciences from "./components/bookcomponents/HealthSciences";
import MilitarySciences from "./components/bookcomponents/MilitarySciences";
import Professions from "./components/bookcomponents/Professions";
import Development from "./components/bookcomponents/Development";
import { BookDisplay } from "./components/bookcomponents/BookDisplay";
import { TutorialDisplay } from "./components/tutorialscomponents/TutorialDisplay";
import  QuizDisplay  from "./components/quizzescomponents/QuizDisplay";
import  QuizzesCategory  from "./components/quizzescomponents/QuizzesCategory";


import Disclaimer from "./components/Disclaimers";
import NotFound from "./components/NotFound";
import Products from "./components/Products";
import TutorialsCategory from "./components/tutorialscomponents/TutorialsCategory";
import Contact from "./components/Contact";
import Cookies from "./components/Cookies";




const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <Home></Home> }></Route>

            <Route path = "/login" element = { <Login></Login> } ></Route>
            <Route path = "/signup" element = { <Signup></Signup> } ></Route>
            <Route path = "/resetpass" element = { <ResetPass></ResetPass> } ></Route>

            <Route path = "/books" element = { <BooksMain></BooksMain> } ></Route>  
            <Route path = "/books/formal-science" element = { <FormalSciences></FormalSciences> } ></Route>   
            <Route path = "/books/social-science" element = { <SocialSciences></SocialSciences> } ></Route> 
            <Route path = "/books/natural-science" element = { <NaturalSciences></NaturalSciences> } ></Route> 
            <Route path = "/books/health-science" element = { <HealthSciences></HealthSciences> } ></Route> 
            <Route path = "/books/military-science" element = { <MilitarySciences></MilitarySciences> } ></Route> 
            <Route path = "/books/engineering" element = { <Engineering></Engineering> } ></Route> 
            <Route path = "/books/applied-science" element = { <Professions></Professions>} ></Route> 
            <Route path = "/books/literature" element = { <Literature></Literature> } ></Route>
            <Route path = "/books/visual-art" element = { <VisualArt></VisualArt> } ></Route> 
            <Route path = "/books/performing-art" element = { <PerformingArt></PerformingArt> } ></Route>    
            <Route path = "/books/religion" element = { <Religion></Religion> } ></Route>
            <Route path = "/books/development" element = { <Development></Development> } ></Route>
            <Route path = "/books/other" element = { <Other></Other> } ></Route>
            <Route path = "/books/book/:uuid" element = { <BookDisplay></BookDisplay> } ></Route>

            <Route path = "/tutorials" element = { <TutorialsMain></TutorialsMain> } ></Route>
            <Route path = "/tutorials/:category" element = { <TutorialsCategory></TutorialsCategory> } ></Route>
            <Route path = "/tutorials/tutorial/:id" element = { <TutorialDisplay></TutorialDisplay> } ></Route>

            <Route path = "/quizzes" element = { <QuizzesMain></QuizzesMain> } ></Route>
            <Route path = "/quizzes/:category" element = { <QuizzesCategory></QuizzesCategory> } ></Route>
            <Route path = "/quizzes/quiz/:id" element = { <QuizDisplay></QuizDisplay> } ></Route>

            <Route path = "/utilities/summarizer" element = { <Summarizer></Summarizer> } ></Route>
            <Route path = "/utilities/dictionaries" element = { <Dictionaries></Dictionaries> } ></Route>
            <Route path = "/utilities/dictionary" element = { <EnDictionary></EnDictionary> } ></Route>
            <Route path = "/utilities/rhymes" element = { <RhymesFinder></RhymesFinder> } ></Route>
            <Route path = "/policy" element = { <Policy/> } ></Route>
            <Route path = "/cookies" element = { <Cookies/> } ></Route>
            <Route path = "/terms" element = { <Term/> } ></Route>
            <Route path = "/permissions" element = { <Permissions/> } ></Route>
            <Route path = "/learn-more" element = { <About/> } ></Route>
            <Route path ="/donate" element = { <Donate></Donate> } ></Route>
            <Route path ="/disclaimer" element = { <Disclaimer></Disclaimer> } ></Route>
            <Route path ="/products" element = { <Products/> } ></Route>
            <Route path ="/contact" element = { <Contact/> } ></Route>
            <Route path ="/about" element = { <About/> } ></Route>
          <Route path='*' element={<NotFound />}></Route>

          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App