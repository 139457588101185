import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { CustomCard } from "./BooksMain";


function NaturalSciences() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/books/natural-science")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])
  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a book in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {



    return (
      <>
        <br /><br />
          <Helmet>
              <meta charSet="utf-8"/>
              <title>Access ebooks in the natural sciences category</title>
              <link rel="canonical" href="https://libpeople.com/books/natural-science"/>
              <meta name="description"
                    content="Access free ebooks in Physics, chemistry, geology, astrology and biology"/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content="Access ebooks in the natural sciences category."/>
              <meta property="og:description"
                    content="Access free ebooks in Physics, chemistry, geology, astrology and biology."/>
          </Helmet>
          <div class="mt-4 px-4 btn-group btn-group-scrollable">
	        <a href={`/books`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; Books</a>
        </div>        
        <div className="w-full justify-center items-center">
        <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
                <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Natural Sciences</b></h2>
            </div>

            <div className="flex justify-center items-center container mx-auto px-2">
            <p  className="text-black-500 dark:text-gray-400">
              <span>Books in this category consist of the following subcategories:</span> 
              <span className="font-bold text-sky-500 px-1">Physics, Chemistry, 
              Geology, 
              Astrology, </span>
              <span>and</span>
              <span className="font-bold text-sky-500 px-1">Biology</span> 
              <span>(biochemistry, zoology, biophysics, anatomy, botany).</span> Natural sciences represent the systematic study of the physical and natural world through observation, experimentation, and theoretical analysis. This vast field encompasses disciplines such as physics, chemistry, biology, astronomy, and earth sciences, each exploring different aspects of the natural universe, from the smallest subatomic particles to the largest cosmic structures. These sciences seek to understand fundamental laws, patterns, and processes that govern natural phenomena.

                The methodology of natural sciences relies heavily on the scientific method, involving careful observation, hypothesis formation, experimental testing, and theory development. Scientists in these fields use sophisticated instruments, advanced mathematical models, and rigorous experimental procedures to investigate natural phenomena, uncover new relationships, and validate existing theories. This approach has led to groundbreaking discoveries that have revolutionized our understanding of life, matter, energy, and the universe itself.

                Reading natural sciences literature is crucial because it keeps readers informed about the latest discoveries, theories, and technological advances that shape our understanding of the natural world. These materials provide insights into cutting-edge research, experimental methodologies, and theoretical frameworks that continue to evolve. They help readers appreciate the complexity and interconnectedness of natural systems, from ecological relationships to physical laws governing the universe. Regular engagement with natural sciences literature also develops critical thinking skills, scientific literacy, and an evidence-based approach to understanding the world. This knowledge is essential not only for scientific advancement but also for addressing global challenges such as climate change, biodiversity loss, and sustainable resource management.
              
            </p> 
            </div>

            <div className="px-4 py-6 flex justify-center items-center">
              <input  type="text" placeholder="Search by title, author or category" className="input-ghost-primary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>

    <div className="container px-5 py-5 mx-auto flex flex-wrap">

    {items.filter((book) => //items here
              {
                if(searchItem === "") {
                  return book;
                }
                else if 
                (book.Title.toLowerCase().includes(searchItem.toLowerCase()) || book.Author.toLowerCase().includes(searchItem.toLowerCase()) || book.Category.toLowerCase().includes(searchItem.toLowerCase())) {
                  return book;
                }
                else return null
                
              }).map((book) => //items here

              <CustomCard key={book.Id} 
              id = {book.Id}
              image = {book.ImageName}
              title={book.Title} 
              pages={book.Pages}
              date={book.Publication}
              language={book.Language} 
              category={book.Category} 
                author={book.Author}  >
            
            </CustomCard>)}
    </div>

        </div>

      
      </>

    );}
  }
  export default NaturalSciences;