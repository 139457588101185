import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import formal from "../../assets/images/statistics.jpg";
import social from "../../assets/images/law.jpg";
import natural from "../../assets/images/biology.jpg";
import health from "../../assets/images/health.jpg";
import military from "../../assets/images/military.jpg";
import applied from "../../assets/images/applied.jpg";
import engineering from "../../assets/images/engineering.jpg";
import language from "../../assets/images/language.jpg";
import visual from "../../assets/images/film.jpg";
import performing from "../../assets/images/theatre.jpg";
import religion from "../../assets/images/religion.jpg";
import development from "../../assets/images/development.jpg";
import other from "../../assets/images/other.jpg";



function CustomCard({image, title, author,category, language, pages, date, id}){
  return(
    <>
      <div className="w-full xl:w-1/3 md:w-1/2 p-2">
        <div className="border border-gray-300 p-6 rounded-lg hover:bg-slate-200  dark:bg-slate-800 dark:border-slate-700">
          <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
          <img className="avatar" src={image} alt="" />
          </div>
          <h2 className="font-bold text-gray-900 dark:text-gray-400 mb-2">{title}</h2>
          <p className="leading-relaxed text-gray-900 dark:text-gray-400  text-sm">{author}</p>
          <p className="leading-relaxed text-sm text-sky-600 italic">
          <span className="font-bold text-sm">{category} </span> 
            <span className="px-2 text-gray-500 text-xs">{language}</span>  
            <span className="px-2 text-gray-500 text-xs">{pages} pages</span>  
            <span className="text-gray-500 text-xs">{date}</span>  
            </p>
          <a href={"/books/book/"+id} className="mt-3 text-purple-500 inline-flex items-center font-bold hover:animate-bounce">Read
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
        </div>
      </div>
    </>
  )
}


function BookCategoryCard({id, image, category, subcategory, url}){
  return (
    <>
      <div className="md:w-1/3 lg:w-1/3 w-full  text-gray-900 dark:text-white">
        <div className="card shadow w-80 m-4">
          <figure>
            <img src={image} alt="integral" />
          </figure>
          <div className="card-body flex items-center" >
            <img className="avatar" src={image} alt="" />
              <h2 className="card-title font-bold"> {category}</h2>
              {(subcategory ==="") && <p></p>}
              <p className="text-sm">{subcategory}</p>
              { (id%2 === 0) &&
                <a  className="w-full btn bg-orange-700 hover:bg-orange-900 text-white btn-md" href={`/books/${url}`}><ion-icon name="arrow-forward-outline"></ion-icon></a>
              }
              { (id%2 === 1) &&
                <a  className="w-full btn bg-blue-700 hover:bg-blue-900 text-white btn-md" href={`/books/${url}`}><ion-icon name="arrow-forward-outline"></ion-icon></a>
              }
          </div>
        </div>

      </div>
    </>
  );
}



function BooksMain() {
    var old_items = [
        {Id:1, Category:"Formal Sciences", Url:"formal-science", Image:formal, SubCategories:"Math, Statistics"},
        {Id:2, Category:"Social Sciences", Url:"social-science", Image:social, SubCategories:"Law, Psychology, Political Science, Geography, Anthropology, History, Philosophy, Economy, Archaeology, Sociology, Finance"},
        {Id:3, Category:"Natural Sciences", Url:"natural-science", Image:natural, SubCategories:"Physics, Chemistry, Geology, Astrology, Biology"},
        {Id:4, Category:"Health Sciences", Url:"health-science", Image:health, SubCategories:""},
        {Id:5, Category:"Military & Naval Sciences", Url:"military-science", Image:military, SubCategories:""},
        {Id:6, Category:"Professions & Applied Sciences", Url:"applied-science", Image:applied, SubCategories:"Architecture, Agriculture, Business, Logistics, Forestry"},
        {Id:7, Category:"Engineering",  Url:"engineering",Image:engineering, SubCategories:"Mechanica, Aerospatial, Computer"},
        {Id:8, Category:"Language and Literature",  Url:"language",Image:language, SubCategories:""},
        {Id:9, Category:"Visual Arts",  Url:"visual-art",Image:visual, SubCategories:"Drawing, Painting, Photography, Sculpture"},
        {Id:10, Category:"Performing Arts",  Url:"performing-art",Image:performing, SubCategories:"Music, Dance, Film, Theatre"},
        {Id:11, Category:"Religion",  Url:"religion",Image:religion,  SubCategories:"Christianity, Islam, Buddhism, Hinduism, Judaism"},
        {Id:12, Category:"Self-development", Url:"development", Image:development, SubCategories:""},
        {Id:13, Category:"Other",  Url:"other",Image:other, SubCategories:""},
      ];
  var items = [
    {Id:1, Category:"Formal Sciences", Url:"formal-science", Image:formal, SubCategories:"Math, Statistics"},
    //{Id:2, Category:"Social Sciences", Url:"social-science", Image:social, SubCategories:"Law, Economy, Finance ..."},
    {Id:3, Category:"Natural Sciences", Url:"natural-science", Image:natural, SubCategories:"Physics, Chemistry, Biology ..."},
    {Id:4, Category:"Health Sciences", Url:"health-science", Image:health, SubCategories:""},
    {Id:5, Category:"Military & Naval Sciences", Url:"military-science", Image:military, SubCategories:""},
    //{Id:6, Category:"Professions & Applied Sciences", Url:"applied-science", Image:applied, SubCategories:"Architecture, Agriculture, Business ..."},
    {Id:7, Category:"Engineering",  Url:"engineering",Image:engineering, SubCategories:"Mechanica, Aerospatial, Computer"},
    //{Id:8, Category:"Language and Literature",  Url:"literature",Image:language, SubCategories:""},
    //{Id:9, Category:"Visual Arts",  Url:"visual-art",Image:visual, SubCategories:"Painting, Photography, Sculpture ..."},
    //{Id:10, Category:"Performing Arts",  Url:"performing-art",Image:performing, SubCategories:"Music, Dance, Film, Theatre"},
    //{Id:11, Category:"Religion",  Url:"religion",Image:religion,  SubCategories:"Christianity, Buddhism, Hinduism ..."},
    {Id:12, Category:"Self-development", Url:"development", Image:development, SubCategories:""},
    //{Id:13, Category:"Other",  Url:"other",Image:other, SubCategories:""},
  ];
    return (
      <>

          <Helmet>
              <meta charSet="utf-8"/>
              <title>Instantly get access to thousands of free ebooks</title>
              <link rel="canonical" href="https://libpeople.com/books"/>
              <meta name="description"
                    content="Access free ebooks in science, literature, art, religion, profession, health, self-development, etc."/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content="Instantly get access to thousands of free ebooks."/>
              <meta property="og:description"
                    content="Access free ebooks in science, literature, art, religion, profession, health, self-development, etc."/>
          </Helmet>


          <section className="relative">
              <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="border-t py-12 [border-image:linear-gradient(to_right,transparent,theme(colors.slate.400/.25),transparent)1] md:py-20">

          <div className=" py-12 container mx-auto px-2">
            <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Books</b></h2>
            <p>Reading books has many benefits, including improving intellectual and psychological abilities. 
              Every book you read offers endless opportunities to discover and learn new ideas. 
              Reading gives you information and knowledge, as well as many other benefits. 
              The benefits of reading include the reader being exposed to new words directly from the context of what they read, 
              improving their soft skills. The more you read, the more you are exposed to new words, 
              which gradually become part of your repertoire. This is one of the most important benefits of reading; 
              you should learn how to make the most of it.</p>
            <p>Most readers find it easy to express themselves, and it is easy for them to talk about their feelings 
              and emotions or have a simple conversation. Empathy refers to the ability to understand or feel the experience 
              of others and relate it to yourself. It's a skill you can learn and practice every day. 
              When you read the thoughts and feelings of characters, you know what it's 
              like to be a part of their story and learn to live like another person with a 
              completely different outlook on life than you. Reading books increases your empathy 
              because your brain knows how to feel in someone else's shoes. 
              If you can apply your knowledge to real life, you will gain a new superpower.</p> 
            <p>For all those advantages of reading books, we gathered as many free books as possible on this platform, 
              the Library of the People.</p>
            </div>
          <div className="py-5 mx-auto flex flex-wrap">
            {items.map(book => 
                <BookCategoryCard key={book.Id} 
                id = {book.Id}
                category={book.Category}
                subcategory={book.SubCategories}
                url={book.Url}
                image={book.Image}
                >
              </BookCategoryCard>)}
          </div>
          </div>
        </div>
      </section>

      </>

    );
  }
  export  {BooksMain, CustomCard};