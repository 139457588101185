import React, {useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { CustomCard } from "./BooksMain";


function Religion() {
    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      fetch("https://goldfish-app-asx3v.ondigitalocean.app/books/religion")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
  
    }, [])
  
    if (error) {
      return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
    } 
    else if (items === null || items.length === 0) {
      return <div className="px-6 py-24 flex justify-center items-center">
          There has yet to be a book in this category. We are working hard on it. Please come back later  !</div>;
    }
    
    else {
  
      return (
        <>
          <br /><br />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Access ebooks in the religion category</title>
                <link rel="canonical" href="https://libpeople.com/books/religion"/>
                <meta name="description" content="Access free ebooks in Christianity, Islam, Buddhism, etc"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="Access ebooks in the religion category."/>
                <meta property="og:description" content="Access free ebooks in Christianity, Islam, Buddhism, etc."/>
            </Helmet>
            <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href={`/books`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; Books</a>
        </div>
          <div className="w-full justify-center items-center">
          <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
                <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Religion</b></h2>
            </div>
            <div className="flex justify-center items-center container mx-auto px-2">
            <p  className="text-black-500 dark:text-gray-400">
              <span>Books in this category consist of the following subcategories:</span> 
              <span className="font-bold text-sky-500 px-1">Christianity, Islam, Judaism, Buddhism, Hindiusm
              </span>. Religion encompasses the diverse systems of beliefs, practices, traditions, and spiritual experiences that humans have developed to understand and connect with the divine or sacred aspects of existence. It includes organized faiths, spiritual philosophies, sacred texts, rituals, moral codes, and cultural practices that address fundamental questions about life's meaning, purpose, and humanity's place in the universe.

                Religious traditions provide frameworks for understanding existence, morality, and human relationships with both the divine and fellow beings. They typically involve elements such as worship practices, ethical guidelines, community formation, and spiritual disciplines. These systems have profoundly influenced human civilization, shaping art, literature, law, social structures, and cultural values throughout history, while continuing to play significant roles in modern society and individual identity formation.

                Reading religious literature is essential because it provides insight into the foundational texts, philosophical interpretations, and evolving understandings of various faith traditions. These materials offer valuable perspectives on spiritual wisdom, ethical teachings, and the historical development of religious thought. They help readers understand different worldviews, appreciate diverse cultural perspectives, and explore profound questions about existence and meaning. Regular engagement with religious texts and scholarly works deepens spiritual understanding, promotes interfaith dialogue, and enhances cultural literacy. This knowledge is crucial for both personal spiritual growth and broader cultural understanding in an increasingly interconnected world where religious literacy contributes to mutual respect and peaceful coexistence among different faith traditions.
              
              
            </p> 
            </div>
              <div className="px-4 py-6 flex justify-center items-center">
                <input  type="text" placeholder="Search by title, author or category" className="input-ghost-primary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
              </div>
  
      <div className="container px-5 py-5 mx-auto flex flex-wrap">
  
      {items.filter((book) => //items here
              {
                if(searchItem === "") {
                  return book;
                }
                else if 
                (book.Title.toLowerCase().includes(searchItem.toLowerCase()) || book.Author.toLowerCase().includes(searchItem.toLowerCase()) || book.Category.toLowerCase().includes(searchItem.toLowerCase())) {
                  return book;
                }
                else return null
                
              }).map((book) => //items here

              <CustomCard key={book.Id} 
              id = {book.Id}
              image = {book.ImageName}
              title={book.Title} 
              pages={book.Pages}
              date={book.Publication}
              language={book.Language} 
              category={book.Category} 
                author={book.Author}  >
            
            </CustomCard>)}
      </div>
  
          </div>
  
        
        </>
  
      );}
  }
  export default Religion;