import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { CustomCard } from "./BooksMain";


function HealthSciences() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/books/health-science")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a book in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {



    return (
      <>
        <br /><br />
          <Helmet>
              <meta charSet="utf-8"/>
              <title>Access ebooks in the health sciences category</title>
              <link rel="canonical" href="https://libpeople.com/books/health-science"/>
              <meta name="description" content="Access free ebooks in health science"/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content="Access ebooks in the health sciences category."/>
              <meta property="og:description" content="Access free ebooks in health science."/>
          </Helmet>
          <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href={`/books`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; Books</a>
        </div>
          <div className="w-full justify-center items-center">
              <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
                  <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Health
                      Sciences</b></h2>
              </div>
              <div className="flex justify-center items-center container mx-auto px-2">
                  <p className="text-black-500 dark:text-gray-400">
                      Health sciences encompass a broad field of study focused on understanding human health, preventing and treating diseases, and promoting overall well-being. This multidisciplinary domain includes medicine, nursing, pharmacy, nutrition, public health, dentistry, and various allied health professions. It combines biological and medical research with practical healthcare delivery, incorporating both traditional knowledge and cutting-edge scientific discoveries.

                      The field integrates theoretical understanding of human anatomy, physiology, and pathology with practical clinical skills and evidence-based treatment approaches. Health sciences professionals work to understand complex interactions between genetic factors, environmental influences, lifestyle choices, and their impacts on human health. They employ various methodologies ranging from laboratory research to clinical trials, epidemiological studies, and patient care protocols.

                      Reading health sciences literature is vital because healthcare knowledge constantly evolves with new research findings, treatment methods, and technological advances. These materials provide essential updates on medical breakthroughs, best practices in patient care, and emerging health challenges. They help professionals stay current with the latest treatment protocols, understand new diseases and their management, and learn about advancements in medical technology. Regular study of health sciences literature also supports evidence-based practice, enhances clinical decision-making skills, and ensures that healthcare providers can offer the most effective and up-to-date care to their patients. This ongoing education is crucial in a field where outdated knowledge could directly impact human lives and well-being.



                  </p>

              </div>
              <div className="px-4 py-6 flex justify-center items-center">
                  <input type="text" placeholder="Search by title, author or category"
                         className="input-ghost-primary input w-full max-w-md" value={searchItem}
                         onChange={event => setSearchItem(event.target.value)}></input>
              </div>

              <div className="container px-5 py-5 mx-auto flex flex-wrap">

                  {items.filter((book) => //items here
                  {
                      if (searchItem === "") {
                          return book;
                      } else if
                      (book.Title.toLowerCase().includes(searchItem.toLowerCase()) || book.Author.toLowerCase().includes(searchItem.toLowerCase()) || book.Category.toLowerCase().includes(searchItem.toLowerCase())) {
                          return book;
                      } else return null

                  }).map((book) => //items here

                      <CustomCard key={book.Id}
                                  id={book.Id}
                                  image={book.ImageName}
                                  title={book.Title}
                                  pages={book.Pages}
                                  date={book.Publication}
                                  language={book.Language}
                                  category={book.Category}
                                  author={book.Author}>

                      </CustomCard>)}
              </div>

          </div>


      </>

    );
  }
}

export default HealthSciences;