import {React, useState, useEffect} from "react";
function SpotLight(){
    return (
      <section className="">
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-wrap text-gray-600 dark:text-white">

          <div className="lg:pt-12 pt-6 w-full md:w-3/12 px-4 text-center">
            <div className="card relative flex flex-col min-w-0 break-words  w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"  fill="white"><path d="M202.24 74C166.11 56.75 115.61 48.3 48 48a31.36 31.36 0 00-17.92 5.33A32 32 0 0016 79.9V366c0 19.34 13.76 33.93 32 33.93 71.07 0 142.36 6.64 185.06 47a4.11 4.11 0 006.94-3V106.82a15.89 15.89 0 00-5.46-12A143 143 0 00202.24 74zM481.92 53.3A31.33 31.33 0 00464 48c-67.61.3-118.11 8.71-154.24 26a143.31 143.31 0 00-32.31 20.78 15.93 15.93 0 00-5.45 12v337.13a3.93 3.93 0 006.68 2.81c25.67-25.5 70.72-46.82 185.36-46.81a32 32 0 0032-32v-288a32 32 0 00-14.12-26.61z"/></svg>
                </div>
                <h2 className="text-xl font-semibold">Books</h2>
                <p className="mt-2 mb-4">
                Access thousands of free ebooks covering science, engineering, literature, arts, religion, and self-development.
                </p>
                <a href="/books"   className="btn btn-primary">Read books</a>
              </div>
            </div>
          </div>

          <div className="w-full md:w-3/12 px-4 text-center">
            <div className="card relative flex flex-col min-w-0 break-words  w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"  fill="white"><path d="M459.94 53.25a16.06 16.06 0 00-23.22-.56L424.35 65a8 8 0 000 11.31l11.34 11.32a8 8 0 0011.34 0l12.06-12c6.1-6.09 6.67-16.01.85-22.38zM399.34 90L218.82 270.2a9 9 0 00-2.31 3.93L208.16 299a3.91 3.91 0 004.86 4.86l24.85-8.35a9 9 0 003.93-2.31L422 112.66a9 9 0 000-12.66l-9.95-10a9 9 0 00-12.71 0z"/><path d="M386.34 193.66L264.45 315.79A41.08 41.08 0 01247.58 326l-25.9 8.67a35.92 35.92 0 01-44.33-44.33l8.67-25.9a41.08 41.08 0 0110.19-16.87l122.13-121.91a8 8 0 00-5.65-13.66H104a56 56 0 00-56 56v240a56 56 0 0056 56h240a56 56 0 0056-56V199.31a8 8 0 00-13.66-5.65z"/></svg>
                </div>
                <h2 className="text-xl font-semibold">
                Tutorials
                </h2>
                <p className="mt-2 mb-4">
                Access detailed tutorials covering programming fundamentals, music, artificial intelligence, CAD and office productivity.
                </p>
                <a href="/tutorials"  className="btn btn-outline-secondary">Read tutorials</a>
              </div>
            </div>
          </div>

          <div className="lg:pt-12 pt-6 w-full md:w-3/12 px-4 text-center">
            <div className="card relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" fill="white"><path d="M400 48H112a64.07 64.07 0 00-64 64v288a64.07 64.07 0 0064 64h288a64.07 64.07 0 0064-64V112a64.07 64.07 0 00-64-64zm-35.75 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z"/></svg>
                </div>
                <h2 className="text-xl font-semibold">
                  Quizzes
                </h2>
                <p className="mt-2 mb-4">
                Access thousands of quizzes and test your knowledge of various scientific topics, historical events and celebrities.
                </p>
                <a href="/quizzes"  className="btn btn-primary">Take quizzes</a>
              </div>
            </div>
          </div>

          <div className="w-full md:w-3/12 px-4 text-center">
            <div className="card relative flex flex-col min-w-0 break-words  w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"  fill="white"><path d="M459.94 53.25a16.06 16.06 0 00-23.22-.56L424.35 65a8 8 0 000 11.31l11.34 11.32a8 8 0 0011.34 0l12.06-12c6.1-6.09 6.67-16.01.85-22.38zM399.34 90L218.82 270.2a9 9 0 00-2.31 3.93L208.16 299a3.91 3.91 0 004.86 4.86l24.85-8.35a9 9 0 003.93-2.31L422 112.66a9 9 0 000-12.66l-9.95-10a9 9 0 00-12.71 0z"/><path d="M386.34 193.66L264.45 315.79A41.08 41.08 0 01247.58 326l-25.9 8.67a35.92 35.92 0 01-44.33-44.33l8.67-25.9a41.08 41.08 0 0110.19-16.87l122.13-121.91a8 8 0 00-5.65-13.66H104a56 56 0 00-56 56v240a56 56 0 0056 56h240a56 56 0 0056-56V199.31a8 8 0 00-13.66-5.65z"/></svg>
                </div>
                <h2 className="text-xl font-semibold">
                Tools
                </h2>
                <p className="mt-2 mb-4">
                Access a text summarizer, English dictionary, common expressions bilingual dictionaries and a rhymes finder.
                </p>
                <a href="/utilities/rhymes"  className="btn btn-outline-secondary">Tools</a>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>

    )
}

export default SpotLight;