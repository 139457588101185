import {React, useState} from "react";
import {Helmet} from "react-helmet";


function Summarizer(){

    const [linesItem, setLinesItem] = useState('')
    const [inputText, setInputText] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    var lines = 5;
    const getSummary =() => {
            setButtonClicked(true);
            setIsLoaded(false);

            lines = parseInt(linesItem);
            if (isNaN(lines)) {
              lines = 5;
            }
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ RawText: inputText, Lines:lines })
              };

            const url = "https://goldfish-app-asx3v.ondigitalocean.app/summary";
            
            fetch(url, requestOptions)
            .then(res => res.text())
            .then(
              (result) => {
                setIsLoaded(true);
                setOutputText(result);

              },
              (error) => {
                setIsLoaded(true);
                setError(error);
              }
          );                 
    };

    return(
      <div className="wrapper py-12 h-full">

          <Helmet>
              <meta charSet="utf-8"/>
              <title>Text summarizer</title>
              <link rel="canonical" href="https://libpeople.com/utilities/summarizer"/>
              <meta name="description" content="Text summarizer"/>
              <meta name="keywords" content="english,text,text summarizer"/>
              <meta property="og:title" content="Text summarizer"/>
              <meta property="og:description" content="Text summarizer"/>
              <meta property="og:site_name" content="Text summarizer"/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
          </Helmet>


          <section className="text-gray-600 body-font">
          <div className="container px-5 pt-12 mx-auto">
            <h1 className="text-lg font-medium title-font text-gray-900 dark:text-sky-500 mb-6 text-center"> <b>Summarizer</b> </h1>
            <p className=" text-gray-900 dark:text-gray-500 ">Summarize text of any length. 
              Save time and effort by getting the critical ideas in a long text. 
              To proceed, copy and paste the text. You then enter the number of lines in which you want the text to be summarized. 
              If you don't provide a number of lines, the text is summarized by default in 5 lines.
            </p>
          </div>
        </section>
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-wrap -m-4">
              <div className="card w-full lg:w-1/3 lg:mb-0 mb-6 p-4">
                <div className="h-full text-center">
                <textarea className="textarea-block textarea  textarea-solid text-sm h-96 w-full" placeholder="Past text to be summarized here" value={inputText} onChange={event => setInputText(event.target.value)}/>
                </div>
              </div>
              <div className="w-full lg:w-1/3 lg:mb-0 mb-6 p-4">
                <div className="h-full text-center">
                <div className="px-2  py-2 w-full  flex flex-items justify-center items-center">
                  <button className="btn btn-primary w-full" onClick={getSummary}>Summarize</button>
                  <p className="px-1"> into </p>
                  <input  type="text" placeholder="5" className="input-ghost-secondary input input-sm w-28"  value={linesItem} onChange={event => setLinesItem(event.target.value)}></input>
                  <p className="px-1"> lines </p>

                </div>
                <div className="flex justify-center items-center">
                    {!isLoaded && isButtonClicked && <button className="btn btn-ghost btn-loading"></button>}
                </div>
                <div className="flex justify-center items-center pt-4 text-sm text-red-400">
                    {error && `${error.message}`}
                </div>

                </div>
              </div>
              <div className="card w-full lg:w-1/3 lg:mb-0 p-4">
                <div className="h-full text-cente">
                <textarea className="textarea-block textarea textarea-solid-primary text-sm h-96 w-full" placeholder="Summary will be displayed here"  value={outputText} onChange={event => setOutputText(event.target.value)}/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );     
}
export  default  Summarizer;