import {React, useState, useEffect} from "react";
import {WordListDisplay} from "../../components/utilities/Enfr";




function EnEs() {
    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      fetch("https://goldfish-app-asx3v.ondigitalocean.app/tools/enes")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
  
    }, [])
  
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div><button className="btn btn-ghost btn-loading"></button></div>;
    } else {



  
    return (
      <div>

        <div className="px-2 py-4  flex justify-center items-center">

          <input  type="text" placeholder="Search here" className="input input-primary w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
        </div>
    

        <div className="py-6 flex justify-center items-center">
          <ul className="menu menu-vertical px-1 w-full  max-w-lg">
            {items.filter((wrd) => //items here
            {
              if(searchItem === "") {
                return wrd;
              }
              else if 
              (wrd.Word.toLowerCase().includes(searchItem.toLowerCase())) {
                return wrd;
              }
              else return null
              
            }).map(wrd => 
            <WordListDisplay 
            key={wrd.Id} 
            word ={wrd.Word} 
            translation={wrd.Translation} 
            traudio={""+wrd.TranslationAudio+".mp3"} >

          </WordListDisplay>)}
          </ul>

        </div>


      </div>
    );}
  }
  export default   EnEs;