export default function Cta() {
    return (
      <section className="relative overflow-hidden">
        <div
          className="pointer-events-none absolute bottom-0 left-1/2 -z-10 -mb-24 ml-20 -translate-x-1/2"
          aria-hidden="true"
        >
        </div>
        <div className="max-w6xl mx-auto px-4 sm:px-6">
          <div className=" py-12 md:py-20">
            <div className="mx-auto max-w-3xl text-center">
              <h2
                className="animate-[gradient_6s_linear_infinite] text-indigo-400 pb-8 font-nacelle text-xl font-semibold  md:text-2xl"
                data-aos="fade-up"
              >
                Join the all-in-one learning platform
              </h2>
              <div className="mx-auto max-w-xs sm:flex sm:max-w-none sm:justify-center">
                
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div data-aos="fade-up" data-aos-delay="400">
                <a className="btn text-white bg-sky-600 hover:bg-sky-700 w-full mb-4 sm:w-auto sm:mb-0" href="/books">Get started </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="600">
                <a className="btn text-white bg-gray-700 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="/about">Learn more</a>
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }