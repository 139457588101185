import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';


function QuizCard({category,title, id}){
  return (
    <>
      <div className="p-4 md:w-1/3 lg:w-1/3 w-full  text-gray-900 dark:text-white">
        <div className="flex rounded-lg h-full  bg-gray-100 dark:bg-slate-700  p-8 flex-col">
          <div className="flex items-center mb-3">
            <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-800 text-white flex-shrink-0">
            <ion-icon name="pencil-outline"></ion-icon>

            </div>
            <h2 className="text-base title-font font-medium">{title}</h2>
          </div>
          <div className="flex-grow">
            <a href={"/quizzes/quiz/"+id} className="mt-3 text-indigo-500 inline-flex items-center font-bold">Take Quiz
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}



function QuizzesCategory() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

    // Get a specific parameter
    let { category } = useParams();

  useEffect(() => {
    fetch(`https://goldfish-app-asx3v.ondigitalocean.app/quizzes/category/${category}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null  || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a Quiz in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {


    return (
      <>
        <br /><br />

          <Helmet>
              <meta charSet="utf-8"/>
              <link rel="canonical" href={`https://libpeople.com/quizzes/${category}`}/>
              <title>{`Quizzes in the ${category} category`} </title>
              <meta name="description" content={`Access thousands of quizzes in the  ${category} category.`}/>
              <meta property="og:locale" content="en_US"/>
              <meta property="og:type" content="article"/>
              <meta property="og:title" content={`Quizzes in the ${category} category`}/>
          </Helmet>


          <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href="/quizzes" class="btn  btn-ghost px-4 text-sm text-sky-600"><ion-icon name="arrow-back"></ion-icon>&emsp; Quizzes</a>
        </div>
        <div className="w-full justify-center items-center">

            <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
            <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>{category}</b></h2>

            </div>

{(category == "Biology") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Biology, the study of living organisms and their interactions with the environment, is a foundational science discipline. It explores the complexities of life, from cellular structures and processes to ecosystems and biodiversity. Understanding biology is crucial for advancements in medicine, agriculture, environmental conservation, and biotechnology, impacting health, food security, and the sustainability of our planet.
<br /><b>Importance as a Science Discipline</b>
: Biology helps us comprehend the mechanisms of life, enabling the development of medical treatments, disease prevention strategies, and sustainable practices. It provides insights into genetic inheritance, evolution, and the functioning of various biological systems, laying the groundwork for scientific and technological innovations.
<br /><b>Testing Knowledge Through Quizzes</b>
: Quizzes are an effective tool for reinforcing learning and assessing comprehension in biology. They encourage active recall, helping students to better retain information and identify knowledge gaps. Regular testing through quizzes promotes critical thinking and application of concepts, which are essential skills in scientific inquiry. Additionally, quizzes can make learning interactive and engaging, motivating students to delve deeper into the subject. By testing knowledge through quizzes, learners can better prepare for more complex examinations and real-world applications, ensuring a thorough understanding of biological principles.
          </p>
      </div>
  </div>
</div>
}

{(category == "Political Science") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Political science is the study of government, political processes, institutions, and behavior. It explores the structure and functioning of political systems, the formulation and implementation of public policy, and the interactions among nations. This discipline is crucial for understanding the dynamics of power, governance, and societal change.
          <br /><b>Importance as a Science Discipline</b>: Political science provides insights into how governments operate and make decisions, the role of political parties and interest groups, and the impact of policies on societies. It helps in analyzing the causes and effects of political actions and understanding the principles of justice, democracy, and human rights. This knowledge is vital for developing informed citizens and effective leaders, contributing to the stability and progress of societies.
<br /><b>Testing Knowledge Through Quizzes</b>: Quizzes are an effective method to reinforce learning and assess comprehension in political science. They promote critical thinking by requiring students to apply concepts and theories to real-world scenarios. Regular testing through quizzes helps students retain information, identify gaps in their knowledge, and understand complex political phenomena. Additionally, quizzes encourage active engagement with the material, making the learning process more interactive and enjoyable. By testing knowledge through quizzes, learners can better prepare for advanced studies and careers in politics, public policy, and related fields, ensuring a deeper understanding of political systems and their impact on the world.
          </p>
      </div>
  </div>
</div>
}
{(category == "Anthropology") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          Anthropology is the comprehensive study of humanity, exploring human behavior, cultures, societies, and biological aspects across time. It seeks to understand the full spectrum of human diversity and the commonalities that bind us together. Anthropology is divided into four main subfields: cultural anthropology, biological anthropology, linguistic anthropology, and archaeology.
          <br /><b>Importance as a Science Discipline</b>: Anthropology is crucial for gaining insights into the human experience, from our evolutionary roots to contemporary social structures. By studying diverse cultures and societies, anthropologists contribute to our understanding of human rights, social justice, and cultural preservation. This knowledge is essential for addressing global challenges, fostering intercultural understanding, and promoting inclusive policies.
<br /><b>Testing Knowledge Through Quizzes</b>: Quizzes are an effective way to reinforce learning and assess comprehension in anthropology. They encourage active recall, helping students retain information about complex concepts and theories. Regular testing through quizzes enhances critical thinking, allowing learners to apply anthropological principles to real-world scenarios. Additionally, quizzes make the learning process interactive and engaging, motivating students to delve deeper into the subject matter. By testing knowledge through quizzes, learners can better prepare for advanced studies and careers in anthropology, ensuring a thorough understanding of human diversity and the factors that shape our world.
          </p>
      </div>
  </div>
</div>
}

{(category == "Sociology") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p>
          Sociology is the scientific study of society, social relationships, and social institutions. It aims to understand how human actions and consciousness shape, and are shaped by, surrounding cultural and social structures. Sociologists explore various aspects of social life, from family dynamics and education to crime, religion, and social inequality. 
          <br /><b>Importance as a Science Discipline</b>: Sociology provides valuable insights into the functioning of societies and the complex interactions between individuals and groups. It helps identify patterns and trends in human behavior, uncovering the underlying causes of social issues. This understanding is crucial for developing policies and interventions that promote social justice, equality, and overall societal well-being. Sociology also fosters critical thinking and empathy, encouraging individuals to consider diverse perspectives and the broader social context.
<br /><b>Testing Knowledge Through Quizzes</b>: Quizzes are an effective method for reinforcing learning and assessing comprehension in sociology. They encourage active recall, helping students retain information about key concepts, theories, and research findings. Regular testing through quizzes enhances analytical skills, allowing learners to apply sociological principles to real-world scenarios. Additionally, quizzes make the learning process interactive and engaging, motivating students to explore deeper into the subject matter. By testing knowledge through quizzes, learners can better prepare for advanced studies and careers in sociology, ensuring a thorough understanding of social structures and the factors that influence human behavior.
          </p>
      </div>
  </div>
</div>
}
{(category == "Celebrities") && 

<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
      <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">

          <p> 
          A celebrity is a person who enjoys widespread recognition and fame, often due to their achievements in fields such as entertainment, sports, or public life. Celebrities hold significant influence in modern society, shaping trends, opinions, and cultural norms through their visibility and public persona.

          <br /><b>Role in Modern Society</b>: Celebrities often serve as role models, with their lifestyles and actions being closely followed by fans. They can raise awareness and advocate for important social, environmental, and political issues, leveraging their platforms to effect positive change. Additionally, celebrities contribute to the economy by driving consumer behavior, endorsing products, and creating job opportunities within the media and entertainment industries.

Celebrities also impact societal ideals and aspirations, influencing fashion, beauty standards, and lifestyle choices. Their influence extends to social media, where they engage with audiences directly, fostering a sense of connection and community.

While the celebrity culture has its criticisms, particularly regarding privacy and the creation of unrealistic standards, the role of celebrities in shaping public discourse and popular culture is undeniable. They play a pivotal part in reflecting and driving societal values, trends, and conversations in today's interconnected world.

          </p>
      </div>
  </div>
</div>
}


            <div className="px-6 py-6 flex justify-center items-center">
              <input  type="text" placeholder="Search by title" className="input-ghost-secondary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>

    <div className="container px-5 py-5 mx-auto flex flex-wrap">

    {items.filter((quiz) => //items here
              {
                if(searchItem === "") {
                  return quiz;
                }
                else if 
                (quiz.Title.toLowerCase().includes(searchItem.toLowerCase()) ) {
                  return quiz;
                }
                else return null
                
              }).map(quiz => 
          <QuizCard key={quiz.Id} 
          id = {quiz.Id}
          category={quiz.Category}
          title={quiz.Title}  >

        </QuizCard>)}
    </div>


        </div>

      
      </>

    );}
  }
  export default QuizzesCategory;